import {Links, Meta, Scripts, ScrollRestoration} from '@remix-run/react';
import {Suspense, lazy, useEffect, useState} from 'react';
import type {PropsWithChildren} from 'react';

import {baseUri, DEFAULT_FAVICON} from '@/constants';
import {Theme} from '@/enums';
import useHandle from '@/hooks/useHandle';
import useTheme from '@/hooks/useTheme';
import brochureV2Stylesheet from '@/stylesheets/brochureV2/brochureV2.css?url';
import plusStylesheet from '@/stylesheets/plus/plus.css?url';
import {FaviconLink} from '@/components/shared/Page/favicon';
import {useExperiment} from '@/hooks/useExperiment';
import {EXP_MWEB_APP_BANNER_BROCHURE_1721770869469} from '@/experiments';

import HrefLangs from '../HtmlHead/HrefLangs';
import MetaHydrated from '../HtmlHead/MetaHydrated';
import PageTitle from '../HtmlHead/PageTitle';
import PreconnectHints from '../HtmlHead/PreconnectHints';
import SocialMeta from '../HtmlHead/SocialMeta';

import DataLayer from './DataLayer';
import {useTranslations} from './I18N';

interface WrapperProps {
  data?: any;
  title?: string;
  metaDescription?: string;
  isPureTitle?: boolean;
  hasScrollRestoration?: boolean;
}

function getStylesheets(themes: Theme[]) {
  const stylesheets = [];
  if (themes.includes(Theme.BrochureV2)) {
    stylesheets.push(brochureV2Stylesheet);
  }
  if (themes.includes(Theme.Plus)) {
    stylesheets.push(plusStylesheet);
  }
  return stylesheets;
}

const LazyImportedEditUI = lazy(() => import('../EditUI/EditUI'));

export default function Wrapper({
  data,
  title,
  children,
  metaDescription,
  isPureTitle,
  hasScrollRestoration = false,
}: PropsWithChildren<WrapperProps>) {
  const themes = useTheme();
  const {t, site} = useTranslations();
  const [hasMounted, setHasMounted] = useState(false);
  const {
    manualHrefLangs,
    manualSocialShareImages,
    noIndex,
    noFollow,
    favicon: overrideFavicon,
    bodyClass,
  } = useHandle();
  const robots: string[] = [];
  if (noIndex) {
    robots.push('noindex');
  }
  if (noFollow) {
    robots.push('nofollow');
  }

  const favicon = overrideFavicon || t('htmlHead.favicon') || DEFAULT_FAVICON;

  const editMode = data?.editMode || false;
  const pageTitle = title || t('htmlHead.pageTitle') || 'Shopify';
  const stylesheets = getStylesheets(themes);

  //mweb app banner experiment
  const expMwebAppBanner = useExperiment(
    EXP_MWEB_APP_BANNER_BROCHURE_1721770869469,
  );

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <html lang={site?.locale || 'en'}>
      <head>
        <PageTitle title={pageTitle} isPureTitle={isPureTitle} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {
          /* MWEB app banner experiment on homepage only */
          expMwebAppBanner === 'treatment' && (
            <meta
              name="apple-itunes-app"
              content="app-id=371294472, affiliate-data=ct=brochure_mweb&pt=318852"
            />
          )
        }
        {robots.length > 0 && <meta name="robots" content={robots.join(',')} />}
        <SocialMeta
          pageTitle={pageTitle}
          manualSocialShareImages={manualSocialShareImages}
          metaDescription={metaDescription}
        />
        <Meta />
        {stylesheets.map((stylesheet, index) => (
          <link key={index} rel="stylesheet" href={stylesheet} />
        ))}
        <Links />
        <FaviconLink href={favicon} />
        <link
          rel="apple-touch-icon"
          href="https://cdn.shopify.com/b/shopify-brochure2-assets/c97c60ca19c64a8b5378d9f9e971f7bd.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://cdn.shopify.com/b/shopify-brochure2-assets/c97c60ca19c64a8b5378d9f9e971f7bd.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="https://cdn.shopify.com/b/shopify-brochure2-assets/b13486e5693b246af63c66ab047a6b6b.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="https://cdn.shopify.com/b/shopify-brochure2-assets/8734d76c98437c8ae8a628bbeed3750a.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="https://cdn.shopify.com/b/shopify-brochure2-assets/193f18e4855704ef1716dc0cd750c1ee.png"
        />
        <link
          rel="canonical"
          href={data?.canonicalUrl || data?.url || baseUri}
        />
        <PreconnectHints />
        {manualHrefLangs ? null : <HrefLangs />}
        <MetaHydrated />
      </head>
      <body className={bodyClass}>
        <DataLayer data={data} />
        {editMode && data?.minervaUser && hasMounted ? (
          <Suspense fallback="">
            <LazyImportedEditUI
              handle={data?.handle || 'home'}
              lang={data?.site?.locale || 'en'}
            />
          </Suspense>
        ) : null}
        {children}
        {hasScrollRestoration && <ScrollRestoration />}
        <Scripts />
      </body>
    </html>
  );
}
